import React, {useEffect, useState} from 'react'
import {Button, Card} from "react-bootstrap";
import {API, graphqlOperation} from "aws-amplify";
import {listCourses} from "../../graphql/queries";


export default function HomePage() {

    const [ courses, setCourses] = useState([]);
    useEffect(() => {
            async function getData(){
                /** Commenting dynamic behaviour to allow anonymous access for now.
                 * Ideally create a REST Api endpoint to pull the data. **/
                /**
                const listItems = await API.graphql(
                    graphqlOperation(listCourses)
                );
                console.log(listItems.data.listCourses.items)
                setCourses(listItems.data.listCourses.items);
                 */
                const courses = [
                    {
                        "id":"bf3636fa-0383-4687-9420-a298198e0b11",
                        "title":"Adobe Experience Manager Developer Course | The Complete Guide",
                        "summary":null,
                        "description":null,
                        "image":"/images/aem.png",
                        "originalPrice":"$149.99",
                        "discountedPrice":"$99.99",
                        "createdAt":"2021-07-05T04:02:40.617Z",
                        "updatedAt":"2021-07-05T04:02:40.617Z",
                        "owner":"45d06604-d5d8-49c2-a623-dc1d2cd9586c"
                    }
                ];
                setCourses(courses)
            }

            getData();
    }, [] )

    return (
        <>
        <section className="container p-5">


            { courses.length == 0 &&
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            <div className="d-flex">

                { courses.map( course => {

                    const url = "/course/" + course.id;
                    return (
                            <Card key={course.id} style={{ width: '33rem' }}>
                                <Card.Img variant="top" src={course.image} />
                                <Card.Body>
                                    <Card.Title>{course.title}</Card.Title>
                                    <Card.Text>
                                        {course.summary}
                                    </Card.Text>
                                    <Button variant="primary" href={url}>View Details</Button>
                                </Card.Body>
                            </Card>

                    )
                })}



            </div>

        </section>
      
      
        
       
        </>
    )
}
