import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./style.css";
import { Data } from "./data";


function FAQ() {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <div class="accordian-container">
                <h2 class="heading-clr">Frequently Asked Questions</h2>
                {/* <div class="acc-cards"> */}
                <div class="acc-bg">
                    {Data.map((item) => {
                        return (
                            <>
                                <Accordion expanded={expanded === `{${item.panel}}`} onChange={handleChange(`{${item.panel}}`)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${item.ariacontrols}`} id={`${item.id}`}>
                                        <Typography className='acc-font'>{`${item.question}`}</Typography>
                                        {/* sx={{ width: '33%', flexShrink: 0 }} */}
                                        {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {`${item.answer}`}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        );

                    })}
                </div>
                {/* </div> */}
            </div>

        </>
    )
}

export default FAQ