export const Data = [
    
    {
        name: "Amrapali Palkar",
        review: "Lorem Ipsum has been the industry's standard since the 1500s. Praesent ullamcorper dui turpis.Nulla pellentesque mi non laoreet eleifend. Integer porttitor mollisar lorem, at molestie arcu pulvinar ut.",
        img:"/images/woman.png",
        country:"India"
    },
    {
        name: "Pratik Patil",
        review: "Lorem Ipsum has been the industry's standard since the 1500s. Praesent ullamcorper dui turpis.Nulla pellentesque mi non laoreet eleifend. Integer porttitor mollisar lorem, at molestie arcu pulvinar ut.",
        img:"/images/man.png",
        country:"UK"
    },
    {
        name: "Shweta Gupta",
        review: "Lorem Ipsum has been the industry's standard since the 1500s. Praesent ullamcorper dui turpis.Nulla pellentesque mi non laoreet eleifend. Integer porttitor mollisar lorem, at molestie arcu pulvinar ut.",
        img:"/images/woman.png",
        country:"Canada"
    },

    {
        name: "Prem Vishwakarma",
        review: "Lorem Ipsum has been the industry's standard since the 1500s. Praesent ullamcorper dui turpis.Nulla pellentesque mi non laoreet eleifend. Integer porttitor mollisar lorem, at molestie arcu pulvinar ut.",
        img:"/images/man.png",
        country:"Paris"
    },
]
