import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./style.css";

const Hero = () => {
  return (
    <section className="sect">
        <div className="con">
      <Container className="main">
        <Row className="row">
          <Col lg="7" md="7">
            <div className="hero__content">
                <h4 className="welcome">Welcome to DoNotStopLearning</h4>
              <h2 className="title">
              Your successful online training journey begins here
              </h2>
              <p className="mb-5 sub-title">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                <br />Aut saepe voluptatum earum delectus deserunt id iste,
                quas officiis et repellat!
              </p>
            </div>
            <div className='buttons'>
          <button className="cta first">Get Started</button>
          <button className="cta sec">View Course</button>
        </div>
          </Col>

          <Col className="hero_img" lg="5" md="5">
            <img src="/images/home.png" alt="" className="w-105" />
          </Col>
        </Row>
      </Container>
      </div>
    </section>
  );
};
  
export default Hero;