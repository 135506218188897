import React, { useState } from 'react'
import { Auth } from "aws-amplify";
import { emailValidator, passwordValidator } from './Validator';
import { AiOutlineEye, AiFillEyeInvisible } from 'react-icons/ai'
import Modal from "react-bootstrap/Modal";
import "./style.css";

function LoginPage() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [code, setCode] = useState(null);

    const [errorMessage1, seterrorMessage1] = React.useState('');
    const [errorMessage2, seterrorMessage2] = React.useState('');
    const [errorMessage, seterrorMessage] = React.useState('');
	const [successMessage, setsuccessMessage] = React.useState('');

    const [showConfCodeModal, setShowConfCodeModal] = useState(false);
    const [showLoginErrorModal, setShowLoginErrorModal] = useState(false)
    const [showUserNotFoundModal, setShowUserNotFoundModal] = useState(false);
    const [confCodeModalErr, setConfCodeModalErr] = useState(null);

    const [loginSubmitClicked, setLoginSubmitClicked] = useState(false);
    const [verifySubmitClicked, setVerifySubmitClicked] = useState(false);

    const [passwordEye, setPasswordEye] = useState(false);

    const handlePasswordClick = () => {
        setPasswordEye(!passwordEye);
    }

    async function onLoginSubmit(e) {
        // debugger;
        { window['toggleLoader'](true) }

        e.preventDefault();
        setLoginSubmitClicked(true);
        
        try {
            const user = await Auth.signIn(email, password);
            if (user.code === undefined) {
                { window['toggleLoader'](false) }
                window.location.href = "/";
            }
        } catch (error) {
            setLoginSubmitClicked(false);

            setsuccessMessage('');
        { window['toggleLoader'](false) }
        
        if (email==('') || email==(null)){
            seterrorMessage1('Please enter email id');
        }
		else if(!emailValidator(email)){
            seterrorMessage1('Please enter valid email id');
        }else{
            seterrorMessage1('');
        }
        
        if (password==('') || password==(null)){
            seterrorMessage2('Please enter password');
        }else{
            seterrorMessage2('');
        };


            if (error.code === "UserNotConfirmedException" && passwordValidator(password)) {
                { window['toggleLoader'](false) }
                seterrorMessage(false)
                setShowConfCodeModal(true);
            }
            if (error.code === "NotAuthorizedException") {
                { window['toggleLoader'](false) }
                seterrorMessage(false)
                setShowLoginErrorModal(true);
            }
            if (error.code === "UserNotFoundException" && emailValidator(email)) {
                { window['toggleLoader'](false) }
                seterrorMessage(false)
                setShowUserNotFoundModal(true);
            }

        }

    }

    async function handleConfirmationCode(e) {
        try {
            setVerifySubmitClicked(true);
            await Auth.confirmSignUp(email, code);
            setShowConfCodeModal(false);
            await onLoginSubmit(e);
        } catch (error) {
            if (error.code === "CodeMismatchException") {
                setConfCodeModalErr(error.message);
            }
        }
    }

    return (
        <>
            <div class="loginpg">
                <div class="login-box">
                    <h1>Login</h1>
                    <form class="loginform">
                        <div class="txt_field_container">
                        <div class="txt_field">
                            <input type="mail" autoFocus required onChange={e => setEmail(e.target.value)}/>
                            <span></span>
                            <label>Email Address</label>
                        </div>
                        <span class="ta-error-msg err-class error-txt d-none d-block">
                        {errorMessage1.length > 0 && <div className='errMassage'>{errorMessage1}</div>} </span>
                        </div>
                        <div class="txt_field_container">
                        <div class="txt_field">
                            <input type={(passwordEye === false) ? "password" : "text"} required  onChange={e => setPassword(e.target.value)} />
                            <span></span>
                            <label>Password</label>
                            </div>  
                            <i class="eye-icon">
                            {
                                   (passwordEye === false)? <AiFillEyeInvisible onClick={handlePasswordClick}/> : <AiOutlineEye onClick={handlePasswordClick}/>
                               } 
                            </i>

                            <span class="ta-error-msg err-class error-txt d-none d-block">
                            {errorMessage2.length > 0 && <div className='errMassage'>{errorMessage2}</div>} 
                               
                               </span>
                            </div>
                       
                        <div class="forgetpassword">
                            <a href="/forgot-password">Forgot Password</a>
                        </div>
                        <button class='subbtn' href='#' onClick={e => onLoginSubmit(e)}>Submit
                        </button>
                    </form>
                </div>
            </div>

            <Modal show={showConfCodeModal} onHide={e => setShowConfCodeModal(false)}>
                <Modal.Header>VERIFY ACCOUNT</Modal.Header>
                <Modal.Body>
                    It looks like your email has not been verified.
                    Please enter the code from your email
                    <div className="mb-3">
                        <input type="text" className="form-control" id="email" aria-describedby="emailHelp"
                            onChange={e => setCode(e.target.value)}
                        />
                        {confCodeModalErr &&
                            <div className="alert alert-danger" role="alert">
                                {confCodeModalErr}
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {!verifySubmitClicked &&
                        <button type="submit" className="btn btn-primary"
                            onClick={e => handleConfirmationCode(e)}>Verify</button>
                    }
                    {verifySubmitClicked &&
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    }


                </Modal.Footer>
            </Modal>

            <Modal show={showLoginErrorModal} onHide={e => setShowLoginErrorModal(false)}>
                <Modal.Header>LOGIN ERROR</Modal.Header>
                <Modal.Body>
                    Incorrect username or password
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="btn btn-primary" onClick={e => setShowLoginErrorModal(false)}>Try Again</button>
                </Modal.Footer>
            </Modal>

            <Modal show={showUserNotFoundModal} onHide={e => setShowUserNotFoundModal(false)}>
                <Modal.Header>USER NOT FOUND</Modal.Header>
                <Modal.Body>
                    The email address you are trying is not found in our system. Please register on the site to create a new account
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="btn btn-secondary" onClick={e => setShowUserNotFoundModal(false)}>Try Again</button>
                    <button type="submit" className="btn btn-primary" onClick={e => window.location.href = "/register"}>Register</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default LoginPage;
