
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import 'swiper/swiper.min.css'
import "swiper/modules/effect-coverflow/effect-coverflow.min.css";
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import "./style.css";
import { Data } from "./data";

import { EffectCoverflow, Navigation, Pagination } from "swiper";
export default function Review() {
  return (
    <>
    <div className="section">
     <h2 className="sectionTitle">Reviews from our Community</h2>
          
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 35,
          modifier: 2,
          slideShadows: true,
        }}
      
        loop={true}
        pagination={{clickable: true}}
        navigation={true}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="mySwiper container"
      >
        <section class="testimonials py-5">
        {Data.map((item) => {
          return (
            <>
             <SwiperSlide>
              <div className="container">
                <figure class="image">
                  <img src={`${item.img}`}/>
                </figure>
                <div class="test-info text-center">
                  <h4 class="name">{`${item.name}`}</h4>
                  <div class="client-data-details">
                    <p className="country">{`${item.country}`}</p>
                  </div>
                  <p class="review">
                    {`${item.review}`}
                  </p>
                </div>
              </div>
              </SwiperSlide>
            </>
          );
        })}
       <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
      </section>
      </Swiper>
      </div>
    </>
  );
}