import React from 'react';
import Hero from '../hero/Hero';
import Review from '../review/Review';
import FAQ from '../faq/FAQ';

export default function StyleGuide() {
  return (
    <div>
      <Hero/>
       <Review/>
      <FAQ/>
    </div>
  )
}
