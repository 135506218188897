import React, {useState} from 'react'
import {Auth} from "aws-amplify";
import { AiOutlineEye, AiFillEyeInvisible } from 'react-icons/ai';
import { emailValidator, passwordValidator, fnameValidator, lnameValidator } from './Validator';
import './style.css'

export default function RegistrationPage() {
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [conformPassword, setConformPassword] = useState(null);

    const [errorMessage1, seterrorMessage1] = React.useState('');
    const [errorMessage2, seterrorMessage2] = React.useState('');
    const [errorMessage3, seterrorMessage3] = React.useState('');
    const [errorMessage4, seterrorMessage4] = React.useState('');
    const [errorMessage5, seterrorMessage5] = React.useState('');

    const [signUp, setSignUp] = useState(false);
    const [regSubmitClicked, setRegSubmitClicked] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [passwordEye, setPasswordEye] = useState(false);

    const handlePasswordClick = () => {
        setPasswordEye(!passwordEye);
    }

    async function onRegistrationSubmit(e){

        { window['toggleLoader'](true) }

        e.preventDefault();
        setRegSubmitClicked(true);
        setErrMsg(null);
        try {
            const user = await Auth.signUp({
                username: email,
                password, conformPassword,
                attributes: {
                    email,
                    given_name: firstName,
                    family_name: lastName
                }
            });
            { window['toggleLoader'](false) }
            setSignUp(true);
        } catch (error) {
            { window['toggleLoader'](false) }
            console.log(e);
            setRegSubmitClicked(false);
        
        if ((firstName==('')) || firstName==(null)){
            seterrorMessage1('Please enter first name');
        }else if(!fnameValidator(firstName)){
            seterrorMessage1('Please enter valid first name');
        }else{
            seterrorMessage1('');
        }
         
        if ((lastName==('')) || lastName==(null)){
            seterrorMessage2('Please enter last name');
        }    
        else if(!lnameValidator(lastName)){
            seterrorMessage2('Please enter valid last name');
        }else{
            seterrorMessage2('');
        }
        
        if (email==('') || email==(null)){
            seterrorMessage3('Please enter email id');
        }
		else if(!emailValidator(email)){
            seterrorMessage3('Please enter valid email id');
        }else{
            seterrorMessage3('');
        }
        
        if (password==('') || password==(null)){
            seterrorMessage4('Please enter password');
        }
		else if(!passwordValidator(password)){
            seterrorMessage4('Please enter valid password');
        }else{
            seterrorMessage4('');
        }

        if (conformPassword==('') || conformPassword==(null)){
            seterrorMessage5('');
        }
		else if(conformPassword !== password){
            seterrorMessage5('Password does not match');
        }else{
            seterrorMessage5('');
        }

        }
    }
   
  return (
    <>
     {!signUp &&
    <div>
      <div className='split-screen'>
          <div className='left'>
              <section className='copy'>
                
              </section>
          </div>
          <div className="right">
              <form>
          <section className='copy'>
              <div className='heading'>Register</div>
              <div className='login-container'>
                  <h5>already have an accout? <a href="/login">
                      <span className='signin'>Sign-In</span></a></h5>
                </div>
              </section>
                      <div class="txt_field_container">
                        <div class="txt_field">
                            <input type="text" maxLength={25} required onChange={ e => setFirstName(e.target.value)}/>
                            <span></span>
                            <label>First Name</label>
                        </div>
                        <span class="ta-error-msg err-class error-txt d-none d-block">
                        {errorMessage1.length > 0 && <div className='errMassage'>{errorMessage1 || errorMessage5}</div>} </span>

                    </div>
                    <div class="txt_field_container">
                        <div class="txt_field">
                            <input type="text" maxLength={25} required onChange={ e => setLastName(e.target.value)}/>
                            <span></span>
                            <label>Last Name</label>
                        </div>
                        <span class="ta-error-msg err-class error-txt d-none d-block">
                        {errorMessage2.length > 0 && <div className='errMassage'>{errorMessage2}</div>} </span>

                    </div>
                    <div class="txt_field_container">
                        <div class="txt_field">
                            <input type="mail" required onChange={ e => setEmail(e.target.value)}/>
                            <span></span>
                            <label>Email Id</label>
                        </div>
                        <span class="ta-error-msg err-class error-txt d-none d-block">
                        {errorMessage3.length > 0 && <div className='errMassage'>{errorMessage3}</div>} </span>

                    </div>
                    <div class="txt_field_container">
                        <div class="txt_field">
                            <input type={(passwordEye === false) ? 'password' : 'text'} required onChange={ e => setPassword(e.target.value)}/>
                            <span></span>
                            <label>Password</label>
                        </div>
                        <i class='eye-icon'>
                            {
                                   (passwordEye === false)? <AiFillEyeInvisible onClick={handlePasswordClick}/> : <AiOutlineEye onClick={handlePasswordClick}/>
                               }
                               </i>
                        <span class="ta-error-msg err-class error-txt d-none d-block">
                        {errorMessage4.length > 0 && <div className='errMassage'>{errorMessage4}</div>} </span>

                    </div>

                    <div class="txt_field_container">
                        <div class="txt_field">
                            <input type="password" required onChange={ e => setConformPassword(e.target.value)}/>
                            <span></span>
                            <label>Conform Password</label>
                        </div>
                        <span class="ta-error-msg err-class error-txt d-none d-block">
                        {errorMessage5.length > 0 && <div className='errMassage'>{errorMessage5}</div>} </span>

                    </div>
              <button className='register-btn' type="submit" onClick={e => onRegistrationSubmit(e)}>Register</button>
              </form>
          </div>
      </div>
      </div>
      }

      {signUp &&

<div className="alert alert-success" role="alert">
<h4 className="alert-heading">Congratulations!</h4>
<p>Thanks for signing up. Please check your inbox to get the account verification code. Please check your SPAM or JUNK folder.</p>

</div>
}

      
    </>
  )
}





