
export const emailValidator = email => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email)
}

export const passwordValidator = password => {
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    return passwordRegex.test(password)
}

export const fnameValidator = fname => {
    const fnameRegex = /^[a-zA-Z]+$/;
    return fnameRegex.test(fname)
}

export const lnameValidator = lname => {
    const lnameRegex = /^[a-zA-Z]+$/;
    return lnameRegex.test(lname)
}
